$(function(){
	//baseSlide();

	//アコーディオン
	$('.accordion-btn').click(function(){
		$(this).toggleClass('active');
		$(this).prev('.accordion-body').slideToggle();
	});
	//スムーズスクロール
  $('a[href^="#"]').click(function(){
    var adjust = 0;
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });


	//背景変更
	$(window).on("load scroll resize", function(){
		var page_h = $(document).height();
		var block_h = page_h / 3;
		var position = $(window).scrollTop();
		var showClass = "show";
		if(position < block_h) {
			$('.bg-cover').not('.bg-img01').removeClass(showClass);
			$('.bg-img01').addClass(showClass);
		}
		else if(position < block_h * 2){
			$('.bg-cover').not('.bg-img02').removeClass(showClass);
			$('.bg-img02').addClass(showClass);
		}
		else if(position <= block_h * 3){
			$('.bg-cover').not('.bg-img03').removeClass(showClass);
			$('.bg-img03').addClass(showClass);
		}
	});


  // $(".one").each(function(i, elem){
  //   var one = $(elem).offset().top;
  //   $(window).on("load scroll resize", function(){
  //     var two = $(window).height();

  //     var three = $(window).scrollTop();
  //     var showClass = "show";
  //     var timing = 50; // 50px, add to css
  //     if (three >= one - two + timing){
  //       $(elem).addClass(showClass);
  //     } else {
  //       $(elem).removeClass(showClass);
  //     }
  //   });
  // });
});


// $(window).on('load', function(){
// });
// var currentWidth = window.innerWidth;
// window.addEventListener("resize", function() {
//   if (currentWidth == window.innerWidth) {
//     return;
//   }
//   currentWidth = window.innerWidth;
// });

// var baseSlide = function () {
// 	var key = $('#btnMore');
// 	var target = $('#descriptionHide');
// 	key.on('click', function () {
// 		if (!key.hasClass('open')) {
// 			target.slideDown(200);
// 			key.addClass('open');
// 			key.text('閉じる');
// 		} else {
// 			target.slideUp(200);
// 			key.removeClass('open');
// 			key.text('もっと読む');
// 		}
// 		return false;
// 	});
// };